import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { AdmissionClasses } from './types';

export const admissionClassesApi = createApi({
  reducerPath: 'admissionClassesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/admission-classes`,
  }),
  endpoints: builder => ({
    getAdmissionlasses: builder.query<AdmissionClasses[], void>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetAdmissionlassesQuery } = admissionClassesApi;
