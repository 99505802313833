const normalizePayload = (data: any) => {
  const acc = {};
  if (typeof data === 'string' || typeof data === 'undefined') {
    return data || null;
  }
  for (const key in data) {
    if (data[key] === '' || typeof data[key] === 'undefined') {
      acc[key] = null;
    } else if (typeof data[key] === 'string') {
      acc[key] = data[key].trim();
    } else if (data[key] instanceof Array) {
      acc[key] = data[key].map(normalizePayload);
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      acc[key] = normalizePayload(data[key]);
    } else {
      acc[key] = data[key];
    }
  }
  return acc;
};

export default normalizePayload;
