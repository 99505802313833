import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDevice, MeteringDeviceQueryParams } from './types';

export const meteringDevicesApi = createApi({
  reducerPath: 'meteringDeviceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-devices`,
  }),
  tagTypes: ['MeteringDevice', 'MeteringDevices'],
  endpoints: builder => ({
    getMeteringDevices: builder.query<
      MeteringDevice[],
      MeteringDeviceQueryParams
    >({
      query: ({
        meteringPointId = null,
        commercialMeteringPointId = null,
        gasMeteringNodeId = null,
      }) => ({
        url: '/query',
        method: 'POST',
        data: {
          meteringPointId,
          commercialMeteringPointId,
          gasMeteringNodeId,
        },
      }),
      providesTags: ['MeteringDevices', 'MeteringDevice'],
    }),
    createMeteringDevice: builder.mutation<MeteringDevice, MeteringDevice>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringDevice',
        { type: 'MeteringDevice', id: result.id },
      ],
    }),
    getMeteringDeviceById: builder.query<MeteringDevice, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringDevice', 'MeteringDevice'],
    }),
    updateMeteringDeviceById: builder.mutation<MeteringDevice, MeteringDevice>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringDevice',
        { type: 'MeteringDevice', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringDevicesQuery,
  useGetMeteringDeviceByIdQuery,
  useCreateMeteringDeviceMutation,
  useUpdateMeteringDeviceByIdMutation,
} = meteringDevicesApi;
