import React from 'react';

import { Route, createBrowserRouter } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';

import Pages, {
  getOperatingGasRouteCreateURL,
  getOperatingGasRoutesURL,
} from '@/pages';
import {
  commercialMeteringPointDetailsRoutes,
  commercialMeteringPointsNodeRoutes,
} from '@/pages/CommercialMeteringPointDetails/routes';
import { contractDetailsRoutes } from '@/pages/ContractDetails/routes';
import { gasDistributionStationRoutes } from '@/pages/GasDistributionStationDetails/routes';
import { gasDistributionStationGasThreadRoutes } from '@/pages/GasDistributionStationGasThread/routes';
import { gasThreadRoutes } from '@/pages/GasThreadDetails/routes';
import { gasThreadMeteringPointsRoutes } from '@/pages/GasThreadMeteringPoints/routes';
import {
  meteringPointsNodeRoutes,
  meteringPointsRoutes,
} from '@/pages/MeteringPointDetails/routes';
import { sectorDetailsRoutes } from '@/pages/SectorDetails/routes';

import { DialogType } from '@components/ui/Dialog/types';

const Router = React.lazy(() => import('@/pages/Router'));

const CommercialMeteringPointsView = React.lazy(
  () => import('@/pages/CounterpartDetails/views/CommercialMeteringPoints'),
);

const CounterpartInformation = React.lazy(
  () => import('@/pages/CounterpartDetails/views/Information'),
);
const CounterpartDetails = React.lazy(
  () => import('@/pages/CounterpartDetails'),
);

const AuthCallback = React.lazy(() => import('@/pages/auth/AuthCallback'));
const ChangePassword = React.lazy(() => import('@/pages/auth/ChangePassword'));
const Onboarding = React.lazy(() => import('@/pages/Onboarding'));
const Organizations = React.lazy(() => import('@/pages/Organizations'));
const OrganizationDetails = React.lazy(
  () => import('@/pages/OrganizationDetails'),
);
const OrganizationInformation = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Information'),
);
const OrganizationEmployees = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Employees'),
);
const OrganizationEmployeeDetails = React.lazy(
  () => import('@/forms/EmployeeForm'),
);
const OrganizationSubdivisions = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Subdivisions'),
);
const OrganizationSubdivisionDetails = React.lazy(
  () => import('@/pages/OrganizationDetails/views/SubdivisionDetails'),
);
const OrganizationSubdivisionDetailsInformation = React.lazy(
  () =>
    import(
      '@/pages/OrganizationDetails/views/SubdivisionDetails/views/Information'
    ),
);
const UserGroups = React.lazy(() => import('@/pages/UserGroups'));
const UserGroupDetails = React.lazy(() => import('@/pages/UserGroupDetails'));
const Users = React.lazy(() => import('@/pages/Users'));
const UserDetails = React.lazy(() => import('@/forms/UserForm'));

const GasDistributionStations = React.lazy(
  () => import('@/pages/GasDistributionStations'),
);
const GasDistributionStationDetails = React.lazy(
  () => import('@/pages/GasDistributionStationDetails'),
);
const GasDistributionStationGasThread = React.lazy(
  () => import('@/pages/GasThreadDetails'),
);

const GasThreads = React.lazy(() => import('@/pages/GasThreads'));
const GasThreadDetails = React.lazy(() => import('@/pages/GasThreadDetails'));
const GasThreadMeteringPoints = React.lazy(
  () => import('@/pages/GasThreadMeteringPoints'),
);

const Counterparties = React.lazy(() => import('@/pages/Counterparties'));

const MeteringPoints = React.lazy(() => import('@/pages/MeteringPoints'));
const MeteringPointDetails = React.lazy(
  () => import('@/pages/MeteringPointDetails'),
);
const NodeDetails = React.lazy(() => import('@/pages/NodeDetails'));

const SectorBridges = React.lazy(() => import('@/pages/SectorBridges'));
const SectorBridgeDetails = React.lazy(
  () => import('@/pages/SectorBridgeDetails'),
);
const SectorBridgeDetailsBridgeActs = React.lazy(
  () => import('@/pages/SectorBridgeDetails/views/BridgeActs'),
);
const SectorBridgeDetailsSectors = React.lazy(
  () => import('@/pages/SectorBridgeDetails/views/Sectors'),
);
const BridgeActs = React.lazy(() => import('@/pages/BridgeActs'));
const BridgeActDetails = React.lazy(() => import('@/forms/BridgeActForm'));

const GasRoutes = React.lazy(() => import('@/pages/GasRoutes'));
const GRoutes = React.lazy(() => import('@/pages/GasRoutes/views/Routes'));
const GasRouteDetails = React.lazy(() => import('@/forms/GasRouteForm'));
const OperatingRoutes = React.lazy(
  () => import('@/pages/GasRoutes/views/OperatingRoutes'),
);
const OperatingRouteDetails = React.lazy(
  () => import('@/forms/OperatingGasRouteForm'),
);

const Roles = React.lazy(() => import('@/pages/Roles'));
const RoleDetails = React.lazy(() => import('@/pages/RoleDetails'));

const CommercialMeteringPoints = React.lazy(
  () => import('@/pages/CommercialMeteringPoints'),
);
const CommercialMeteringPointDetails = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails'),
);

const Contracts = React.lazy(() => import('@/pages/Contracts'));
const ContractDetails = React.lazy(() => import('@/pages/ContractDetails'));
const CounterpartContracts = React.lazy(
  () => import('@/pages/CounterpartDetails/views/CounterpartContracts'),
);

const CounterpartRequests = React.lazy(
  () => import('@/pages/CounterpartDetails/views/Requests'),
);

const Sectors = React.lazy(() => import('@/pages/Sectors'));
const SectorDetails = React.lazy(() => import('@/pages/SectorDetails'));

const CommercialMeteringPointOwnerships = React.lazy(
  () => import('@/pages/CommercialMeteringPointOwnerships'),
);

const CommercialMeteringPointOwnershipsFormView = React.lazy(
  () =>
    import(
      '@/pages/CommercialMeteringPointOwnerships/components/CommercialMeteringPointOwnershipsFormView'
    ),
);

const OperationalIncomes = React.lazy(
  () => import('@/pages/OperationalIncomes'),
);
const OperationalIncomeDetails = React.lazy(
  () => import('@/pages/OperationalIncomeDetails'),
);
const OperationalIncomeCosts = React.lazy(
  () => import('@/forms/OperationalIncomeCostsForm'),
);

const FormNine = React.lazy(() => import('@/pages/FormNine'));
const FormNineDetails = React.lazy(() => import('@/pages/FormNineDetails'));

const InfoRequestForm = React.lazy(
  () => import('@/forms/CounterpartRequestsForm/Info'),
);

const routes: RouteObject[] = [
  {
    index: true,
    element: <Organizations />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId`,
    element: <OrganizationDetails />,
    children: [
      {
        index: true,
        element: <OrganizationInformation />,
      },
      {
        path: Pages.EMPLOYEES_PART,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
      {
        path: Pages.SUBDIVISIONS_PART,
        element: <OrganizationSubdivisions />,
      },
    ],
  },
  /*** Start: Main Organization Routes ***/
  {
    path: `/${Pages.MAIN_ORGANIZATION_PART}/:orgId`,
    element: <OrganizationDetails isMainOrganizationView />,
    children: [
      {
        index: true,
        element: <OrganizationInformation isMainOrganizationView />,
      },
      {
        path: Pages.EMPLOYEES_PART,
        element: <OrganizationEmployees isMainOrganizationView />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails isMainOrganizationView />,
          },
        ],
      },
    ],
  },
  {
    path: `/${Pages.MAIN_ORGANIZATION_PART}/:orgId/${Pages.ROLES_PART}`,
    element: <Roles isMainOrganizationView />,
  },
  {
    path: `/${Pages.MAIN_ORGANIZATION_PART}/:orgId/${Pages.ROLES_PART}/:roleId`,
    element: <RoleDetails isMainOrganizationView />,
  },
  /*** End: Main Organization Routes ***/
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId`,
    element: <OrganizationSubdivisionDetails />,
    children: [
      { index: true, element: <OrganizationSubdivisionDetailsInformation /> },
      {
        path: Pages.EMPLOYEES_PART,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
    ],
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.ROLES_PART}`,
    element: <Roles />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId/${Pages.ROLES_PART}`,
    element: <Roles />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.ROLES_PART}/:roleId`,
    element: <RoleDetails />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId/${Pages.ROLES_PART}/:roleId`,
    element: <RoleDetails />,
  },
  { path: Pages.USER_GROUPS, element: <UserGroups /> },
  { path: `${Pages.USER_GROUPS}/:groupId`, element: <UserGroupDetails /> },
  {
    path: Pages.USERS,
    element: <Users />,
    children: [
      {
        path: ':userId',
        element: <UserDetails />,
      },
    ],
  },
  {
    path: Pages.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
  {
    path: Pages.GAS_DISTRIBUTION_STATIONS,
    element: <GasDistributionStations />,
  },
  {
    path: `${Pages.GAS_DISTRIBUTION_STATIONS}/:stationId`,
    element: <GasDistributionStationDetails />,
    children: gasDistributionStationRoutes,
  },
  {
    path: `${Pages.GAS_DISTRIBUTION_STATIONS}/:stationId/gas-threads/:gasThreadId`,
    element: <GasDistributionStationGasThread />,
    children: gasDistributionStationGasThreadRoutes,
  },
  {
    path: Pages.GAS_THREADS,
    element: <GasThreads />,
  },
  {
    path: Pages.CONTRACTS,
    element: <Contracts />,
  },
  {
    path: `${Pages.CONTRACTS}/:contractId`,
    element: <ContractDetails />,
    children: contractDetailsRoutes,
  },
  {
    path: `${Pages.GAS_THREADS}/:gasThreadId`,
    element: <GasThreadDetails />,
    children: gasThreadRoutes,
  },
  {
    path: `${Pages.GAS_THREADS}/:gasThreadId/${Pages.METERING_POINTS}`,
    element: <GasThreadMeteringPoints />,
    children: gasThreadMeteringPointsRoutes,
  },
  {
    path: `${Pages.GAS_THREADS}/:gasThreadId/${Pages.SECTORS}/:sectorId`,
    element: <SectorDetails />,
    children: sectorDetailsRoutes,
  },
  {
    path: Pages.METERING_POINTS,
    element: <MeteringPoints />,
  },
  {
    path: `${Pages.METERING_POINTS}/:meteringPointId`,
    element: <MeteringPointDetails />,
    children: meteringPointsRoutes,
  },
  {
    path: `${Pages.METERING_POINTS}/:meteringPointId/${Pages.METERING_NODES}/:meteringNodeId`,
    element: <NodeDetails />,
    children: meteringPointsNodeRoutes,
  },
  {
    path: `${Pages.COMMERCIAL_METERING_POINTS_PART}/:commercialMeteringPointId/${Pages.METERING_NODES}/:meteringNodeId`,
    element: <NodeDetails />,
    children: commercialMeteringPointsNodeRoutes,
  },
  {
    path: Pages.SECTOR_BRIDGES,
    element: <SectorBridges />,
  },
  {
    path: `${Pages.SECTOR_BRIDGES}/:sectorBridgeId`,
    element: <SectorBridgeDetails />,
    children: [
      {
        path: '',
        element: <SectorBridgeDetailsBridgeActs />,
        children: [{ path: ':bridgeActId', element: <BridgeActDetails /> }],
      },
      {
        path: Pages.SECTORS,
        element: <SectorBridgeDetailsSectors />,
      },
    ],
  },
  {
    path: `${Pages.SECTOR_BRIDGES}/:sectorBridgeId/${Pages.SECTORS}/:sectorId`,
    element: <SectorDetails />,
    children: sectorDetailsRoutes,
  },
  {
    path: Pages.BRIDGE_ACTS,
    element: <BridgeActs />,
    children: [{ path: ':bridgeActId', element: <BridgeActDetails /> }],
  },
  {
    path: Pages.COUNTERPARTIES,
    element: <Counterparties />,
  },
  {
    path: `${Pages.COUNTERPARTIES}/:counterpartId`,
    element: <CounterpartDetails />,
    children: [
      {
        path: '',
        element: <CounterpartInformation />,
      },
      {
        path: `${Pages.COMMERCIAL_METERING_POINTS_PART}`,
        element: <CommercialMeteringPointsView />,
      },
      {
        path: `${Pages.CONTRACTS}`,
        element: <CounterpartContracts />,
      },
      {
        path: `${Pages.REQUESTS}`,
        element: <CounterpartRequests />,
        children: [
          {
            path: `:requestId`,
            element: <InfoRequestForm />,
          },
        ],
      },
    ],
  },
  {
    path: `${Pages.COUNTERPARTIES}/:counterpartId/${Pages.COMMERCIAL_METERING_POINTS_PART}/:commercialMeteringPointId`,
    element: <CommercialMeteringPointDetails />,
    children: commercialMeteringPointDetailsRoutes,
  },
  {
    path: `${Pages.COUNTERPARTIES}/:counterpartId/${Pages.CONTRACTS}/:contractId`,
    element: <ContractDetails />,
    children: contractDetailsRoutes,
  },
  {
    path: Pages.GAS_ROUTES,
    element: <GasRoutes />,
    children: [
      {
        path: '',
        element: <GRoutes />,
        children: [
          {
            path: ':gasRouteId',
            element: <GasRouteDetails />,
          },
        ],
      },
      { path: Pages.OPERATING_GAS_ROUTES_PART, element: <OperatingRoutes /> },
    ],
  },
  {
    path: getOperatingGasRouteCreateURL(),
    element: <OperatingRouteDetails type={DialogType.Create} />,
  },
  {
    path: `${getOperatingGasRoutesURL()}/:operatingGasRouteId`,
    element: <OperatingRouteDetails />,
  },
  {
    path: Pages.COMMERCIAL_METERING_POINTS_PART,
    element: <CommercialMeteringPoints />,
  },
  {
    path: `${Pages.COMMERCIAL_METERING_POINTS_PART}/:commercialMeteringPointId`,
    element: <CommercialMeteringPointDetails />,
    children: commercialMeteringPointDetailsRoutes,
  },
  {
    path: `${Pages.COMMERCIAL_METERING_POINTS_PART}/:commercialMeteringPointId/${Pages.OWNERSHIP_HISTORY}`,
    element: <CommercialMeteringPointOwnerships />,
    children: [
      {
        path: `:ownershipId`,
        element: <CommercialMeteringPointOwnershipsFormView />,
      },
    ],
  },
  {
    path: `${Pages.FORM_NINE}`,
    element: <FormNine />,
  },
  {
    path: `${Pages.FORM_NINE}/:formNineId`,
    element: <FormNineDetails />,
  },
  {
    path: Pages.SECTORS,
    element: <Sectors />,
  },
  {
    path: `${Pages.SECTORS}/:sectorId`,
    element: <SectorDetails />,
    children: sectorDetailsRoutes,
  },
  {
    path: `${Pages.OPERATIONAL_INCOMES}`,
    element: <OperationalIncomes />,
  },
  {
    path: `${Pages.OPERATIONAL_INCOMES}/:operationalIncomeId`,
    element: <OperationalIncomeDetails />,
    children: [
      {
        path: `:dailyIncomeId`,
        element: <OperationalIncomeCosts />,
      },
    ],
  },
];

const getNestedRoutes = (routes: RouteObject[]) => {
  return routes.map(({ children, ...route }, index) => (
    // @ts-ignore
    <Route key={index} {...route}>
      {children?.length ? getNestedRoutes(children) : null}
    </Route>
  ));
};

export const getRoutes = () =>
  createBrowserRouter([
    // Page for oidc authorization flow
    {
      path: '/callback',
      element: <AuthCallback />,
    },
    {
      path: Pages.ONBOARDING,
      element: <Onboarding />,
    },
    {
      path: '*',
      element: <Router>{getNestedRoutes(routes)}</Router>,
    },
  ]);
