import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import { OperatingIncome } from './types';

export const operatingIncomesApi = createApi({
  reducerPath: 'operatingIncomesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/gas-distribution-station-operational-incomes`,
  }),
  tagTypes: ['OperatingIncomes', 'OperatingIncome'],
  endpoints: builder => ({
    getOperatingIncomes: builder.query<OperatingIncome[], void>({
      query: () => ({
        url: `/query`,
        method: 'POST',
        data: {},
      }),
      providesTags: ['OperatingIncomes'],
    }),
    getOperatingIncomeById: builder.query<OperatingIncome, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'OperatingIncome', id }],
    }),
    // createOperatingIncome: builder.mutation<OperatingIncome>({
    //   query: data => ({
    //     url: ``,
    //     method: 'POST',
    //     data,
    //   }),
    //   invalidatesTags: result => [
    //     'OperatingIncomes',
    //     { type: 'OperatingIncome', id: result.id },
    //   ],
    // }),
    // updateOperatingIncome: builder.mutation<
    //   OperatingIncome,
    //   OperatingIncomeRequestPayload
    // >({
    //   query: ({ id, ...data }) => ({
    //     url: `/${id}`,
    //     method: 'PUT',
    //     data,
    //   }),
    //   invalidatesTags: result => [
    //     'OperatingIncomes',
    //     { type: 'OperatingIncome', id: result.id },
    //   ],
    // }),
    // deleteOperatingIncome: builder.mutation<OperatingIncome, string>({
    //   query: id => ({
    //     url: `/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: result => [
    //     'OperatingIncomes',
    //     { type: 'OperatingIncome', id: result.id },
    //   ],
    // }),
  }),
});

export const { useGetOperatingIncomesQuery, useGetOperatingIncomeByIdQuery } =
  operatingIncomesApi;
