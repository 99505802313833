import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

import { MeteringPointsChildrenPath } from '../MeteringPointDetails/routes';

const CommercialMeteringPointInformation = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails/views/Information'),
);
const CommercialMeteringPointEquipment = React.lazy(
  () =>
    import(
      '@/pages/CommercialMeteringPointDetails/views/GasConsumingEquipment'
    ),
);
const MeteringModemDetails = React.lazy(() => import('@/forms/MeteringModem'));
const MeteringDeviceDetails = React.lazy(
  () => import('@/forms/MeteringDeviceForm/MeteringDeviceFormPreviewMode'),
);

const CommercialMeteringPointNodes = React.lazy(() => import('@/pages/Nodes'));

const MeteringNodeInformation = React.lazy(
  () => import('@/pages/NodeDetails/views/Information'),
);
const MeteringDevices = React.lazy(() => import('@/pages/Devices'));

const MeteringNodeSeal = React.lazy(() => import('@/pages/Seal'));
const SealDetails = React.lazy(() => import('@/forms/MeteringNodeSealForm'));

const GasConsumingEquipmentFormView = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails/views/Form'),
);

export const commercialMeteringPointsNodeRoutes: RouteObject[] = [
  {
    index: true,
    element: <MeteringNodeInformation />,
  },
  {
    path: Pages.DEVICES,
    element: <MeteringDevices />,
    children: [
      {
        path: MeteringPointsChildrenPath.MODEMS + '/:modemId',
        element: <MeteringModemDetails />,
      },
      {
        path: ':deviceId',
        element: <MeteringDeviceDetails />,
      },
    ],
  },
  {
    path: `${Pages.EQUIPMENT}`,
    element: <CommercialMeteringPointEquipment />,
    children: [
      {
        path: ':gasConsumingEquipmentId',
        element: <GasConsumingEquipmentFormView />,
      },
    ],
  },
];

export const commercialMeteringPointDetailsRoutes: RouteObject[] = [
  {
    element: <CommercialMeteringPointInformation />,
    index: true,
  },
  {
    path: `${Pages.EQUIPMENT}`,
    element: <CommercialMeteringPointEquipment />,
    children: [
      {
        path: ':gasConsumingEquipmentId',
        element: <GasConsumingEquipmentFormView />,
      },
    ],
  },
  {
    path: Pages.SEALS,
    element: <MeteringNodeSeal />,
    children: [{ path: ':sealId', element: <SealDetails /> }],
  },
  {
    path: Pages.DEVICES,
    element: <MeteringDevices />,
    children: [
      {
        path: Pages.MODEMS + '/:modemId',
        element: <MeteringModemDetails />,
      },
      {
        path: ':deviceId',
        element: <MeteringDeviceDetails />,
      },
    ],
  },
  {
    path: `${Pages.METERING_NODES}`,
    element: <CommercialMeteringPointNodes />,
  },
];
