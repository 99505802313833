import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { CounterpartRequest, CounterpartRequestFilter } from './types';

export const counterpartRequestsApi = createApi({
  reducerPath: 'counterpartRequestsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/commercial-metering-point-code-requests`,
  }),
  tagTypes: ['CounterpartRequest', 'CounterpartRequests'],
  endpoints: builder => ({
    getCounterpartRequests: builder.query<
      CounterpartRequest[],
      CounterpartRequestFilter
    >({
      query: ({ counterpartyId }) => ({
        url: '/query',
        method: 'POST',
        data: { counterpartyId },
      }),
      providesTags: ['CounterpartRequest', 'CounterpartRequests'],
    }),
    getCounterpartRequestById: builder.query<CounterpartRequest, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['CounterpartRequest', 'CounterpartRequests'],
    }),
    createCounterpartRequest: builder.mutation<
      CounterpartRequest,
      CounterpartRequest
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'CounterpartRequest',
        { type: 'CounterpartRequest', id: result.id },
      ],
    }),
    updateCounterpartRequestById: builder.mutation<
      CounterpartRequest,
      CounterpartRequest
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'CounterpartRequest',
        { type: 'CounterpartRequest', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetCounterpartRequestsQuery,
  useCreateCounterpartRequestMutation,
  useGetCounterpartRequestByIdQuery,
  useUpdateCounterpartRequestByIdMutation,
} = counterpartRequestsApi;
