import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeterThroughputs, MeterThroughputsFilter } from './types';

export const meterThroughputsApi = createApi({
  reducerPath: 'meterThroughputsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/meter-throughputs`,
  }),
  endpoints: builder => ({
    getMeterThroughputs: builder.query<
      MeterThroughputs[],
      MeterThroughputsFilter
    >({
      query: ({ isIndustrial = false }) => ({
        url: '/query',
        method: 'POST',
        data: { isIndustrial },
      }),
    }),
  }),
});

export const { useGetMeterThroughputsQuery } = meterThroughputsApi;
