import { MeteringPointActQuery } from '@/features/meteringPointActs/types';

export const mockActs: MeteringPointActQuery[] = [
  {
    actType: {
      id: 'c7062912-18bf-41ae-8f31-eface214a370',
      name: 'Приймання в комерційну експлуатацію',
    },
    actionId: '',
    commercialMeteringPointId: '',
    date: '2024-11-07T22:04:34Z',
    documents: [
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
      {
        name: 'document_file_name2.pdf',
        url: 'document_file_name2.pdf',
      },
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name3.pdf',
      },
    ],
    id: '8d6d03d1-1b23-44b2-bc79-877d60b2c9b8',
    meteringPointId: '',
    number: '4563235',
    outageTypeId: '',
  },
  {
    actType: {
      id: 'e63f1755-3821-487a-b862-b5e1d3f53c66',
      name: 'Обстеження/контрольного огляду',
    },
    actionId: '',
    commercialMeteringPointId: '',
    date: '2024-11-07T15:04:34Z',
    documents: [
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
    ],
    id: '8d6d03d1-1b23-44b2-bc79-877d60b2c9b2',
    meteringPointId: '',
    number: '4563222',
    outageTypeId: '',
  },
  {
    actType: {
      id: '15aa9ee6-fd43-412b-a9a4-9822a518a8e4',
      name: 'Відключення/Підключення газу',
    },
    actionId: '',
    commercialMeteringPointId: '',
    date: '2024-11-07T15:04:34Z',
    documents: [
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
    ],
    id: '8d6d03d1-1b23-44b2-bc79-877d60b2c9b3',
    meteringPointId: '',
    number: '4563111',
    outageTypeId: '',
  },
  {
    actType: {
      id: '4ec20a88-b33d-4bcd-8dc1-2142e388500c',
      name: 'Зняття/Встановлення приладу обліку',
    },
    actionId: '',
    commercialMeteringPointId: '',
    date: '2024-11-07T15:04:34Z',
    documents: [
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
      {
        name: 'document_file_name1.pdf',
        url: 'document_file_name1.pdf',
      },
    ],
    id: '8d6d03d1-1b23-44b2-bc79-877d60b2c9b6',
    meteringPointId: '',
    number: '4563444',
    outageTypeId: '',
  },
];
