import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringSeal } from './types';

export const meteringSealApi = createApi({
  reducerPath: 'meteringSealApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/seals`,
  }),
  tagTypes: ['MeteringSeals', 'MeteringSeal'],
  endpoints: builder => ({
    getMeteringSeal: builder.query<MeteringSeal[], any>({
      query: ({
        meteringPointId = null,
        commercialMeteringPointId = null,
      }) => ({
        url: '/query',
        method: 'POST',
        data: {
          meteringPointId,
          commercialMeteringPointId,
        },
      }),
      providesTags: ['MeteringSeals', 'MeteringSeal'],
    }),
    getMeteringSealById: builder.query<MeteringSeal, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringSeal', 'MeteringSeals'],
    }),
    createMeteringSeal: builder.mutation<MeteringSeal, MeteringSeal>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringSeal',
        { type: 'MeteringSeal', id: result.id },
      ],
    }),
    deleteMeteringSealById: builder.mutation<MeteringSeal, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringSeals',
        { type: 'MeteringSeal', id },
      ],
    }),
  }),
});

export const {
  useGetMeteringSealQuery,
  useGetMeteringSealByIdQuery,
  useCreateMeteringSealMutation,
  useDeleteMeteringSealByIdMutation,
} = meteringSealApi;
