import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import omit from 'lodash-es/omit';

import { useGetOrganizationsQuery } from '@/features/organizations/organizationsApi';
import {
  OrganizationBase,
  OrganizationTypeId,
} from '@/features/organizations/types';
import OrganizationManager from '@/utils/organizationManager';

import { isDeepEqual } from '@mui/x-data-grid/internals';

const ORGANIZATION_ID_KEY = 'ORGANIZATION_ID';

interface OrganizationContextState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  organization: OrganizationBase;
}

export type OrganizationContextType = OrganizationContextState & {
  isMainOffice: boolean;
  changeOrganization: (v: OrganizationBase) => void;
};

const defaultValuesOrganizationContext: OrganizationContextType = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  organization: undefined,
  changeOrganization: () => {},
  isMainOffice: false,
};

const OrganizationContext = createContext(defaultValuesOrganizationContext);

export const useOrganizationContext = () => useContext(OrganizationContext);

export const OrganizationProvider = ({ children }: PropsWithChildren) => {
  const [isMainOffice, setIsMainOffice] = useState(false);
  const [state, setState] = useState<OrganizationContextState>(
    omit(defaultValuesOrganizationContext, 'changeOrganization'),
  );

  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetOrganizationsQuery({
    includeMainOrganization: true,
  });

  useEffect(() => {
    const cachedOrganizationId = localStorage.getItem(ORGANIZATION_ID_KEY);
    OrganizationManager.setState(cachedOrganizationId);

    const payload = {
      organization: undefined,
      isLoading,
      isSuccess,
      isError,
    };

    if (isSuccess && data.length) {
      // check if user belongs to main office
      const mainOrganization = data.find(
        org => org.type.id === OrganizationTypeId.MainOffice,
      );

      if (!isMainOffice && mainOrganization) {
        setIsMainOffice(true);
      }

      // use organization id from cache if valid
      if (cachedOrganizationId) {
        payload.organization = data.find(
          org => org.id === cachedOrganizationId,
        );

        if (payload.organization) {
          return setState(payload);
        } else {
          localStorage.removeItem(ORGANIZATION_ID_KEY);
        }
      }

      // set current organization to main office if user has access, in other case select first from the list
      payload.organization = mainOrganization || data[0];

      localStorage.setItem(ORGANIZATION_ID_KEY, payload.organization.id);
      OrganizationManager.setState(payload.organization.id);
    }

    if (!isDeepEqual(state, payload)) {
      setState(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const changeOrganization = useCallback((value: OrganizationBase) => {
    setState(prev => ({ ...prev, organization: value }));
    localStorage.setItem(ORGANIZATION_ID_KEY, value.id);
    OrganizationManager.setState(value.id);
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        ...state,
        isMainOffice,
        changeOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
