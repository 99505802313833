import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const counterpartyDocumentTypesAp = createApi({
  reducerPath: 'counterpartyDocumentTypesAp',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/counterparty-document-types`,
  }),
  endpoints: builder => ({
    counterpartyDocumentTypes: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: `/`,
      }),
    }),
  }),
});

export const { useCounterpartyDocumentTypesQuery } =
  counterpartyDocumentTypesAp;
