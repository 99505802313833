import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDevicePassportType } from './types';

export const meteringDevicePassportTypeApi = createApi({
  reducerPath: ' meteringDevicePassportTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/meter-passport-types`,
  }),
  endpoints: builder => ({
    getMeteringDevicePassportType: builder.query<
      MeteringDevicePassportType[],
      void
    >({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetMeteringDevicePassportTypeQuery } =
  meteringDevicePassportTypeApi;
