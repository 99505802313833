type StateListener = (token: string | null) => void;

class StateManager {
  private state: string | null;
  private listeners: StateListener[];

  constructor() {
    this.state = null;
    this.listeners = [];
  }

  /**
   * Get the current state.
   */
  public getState(): string | null {
    return this.state;
  }

  /**
   * Set a new state and notify listeners.
   */
  public setState(newState: string | null): void {
    this.state = newState;
    this.notifyListeners();
  }

  /**
   * Add a listener for state updates.
   */
  public addListener(listener: StateListener): void {
    this.listeners.push(listener);
  }

  /**
   * Remove a listener for state updates.
   */
  public removeListener(listener: StateListener): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  /**
   * Notify all listeners of state updates.
   */
  private notifyListeners(): void {
    this.listeners.forEach(listener => listener(this.state));
  }
}

export default StateManager;
