import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  Contract,
  ContractFilters,
  ContractNumberRequestBody,
  ContractQuery,
  ContractReferenceBook,
  ContractRequestBody,
} from './types';

export const contractsApi = createApi({
  reducerPath: 'contractsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL + `/contracts`,
  }),
  tagTypes: ['Contract', 'Contracts', 'ContractReferenceBook'],
  endpoints: builder => ({
    getContracts: builder.query<ContractQuery[], ContractFilters>({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['Contract', 'Contracts'],
    }),
    createContract: builder.mutation<Contract, ContractRequestBody>({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'Contract',
        { type: 'Contract', id: result.id },
      ],
    }),
    updateContract: builder.mutation<Contract, ContractRequestBody>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'Contract',
        { type: 'Contract', id: result.id },
      ],
    }),
    getContractById: builder.query<Contract, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['Contract', 'Contracts'],
    }),
    getContractReferenceBooks: builder.query<ContractReferenceBook, void>({
      query: () => ({
        url: `/reference-books`,
      }),
      providesTags: ['ContractReferenceBook'],
    }),
    updateContractNumberById: builder.mutation<
      Contract,
      ContractNumberRequestBody
    >({
      query: data => ({
        url: `/${data.id}/contract-number`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'Contract',
        { type: 'Contract', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractReferenceBooksQuery,
  useCreateContractMutation,
  useUpdateContractMutation,
  useGetContractByIdQuery,
  useUpdateContractNumberByIdMutation,
} = contractsApi;
