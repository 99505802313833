import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeasurementRange } from './types';

export const measurementRangesApi = createApi({
  tagTypes: ['MeasurementRange'],
  reducerPath: 'measurementRangesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/measurement-ranges`,
  }),
  endpoints: builder => ({
    getMeasurementRanges: builder.query<
      MeasurementRange[],
      { meterThroughputId: string | null }
    >({
      query: ({ meterThroughputId = null }) => ({
        url: '/query',
        method: 'POST',
        data: { meterThroughputId },
      }),
    }),
    getMeasurementRangeById: builder.query<MeasurementRange, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetMeasurementRangesQuery, useGetMeasurementRangeByIdQuery } =
  measurementRangesApi;
