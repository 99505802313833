import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDeviceGroups } from './types';

export const meteringDeviceGroupsApi = createApi({
  reducerPath: 'meteringDeviceGroupsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-device-groups`,
  }),
  tagTypes: ['MeteringDeviceGroups', 'MeteringDeviceGroup'],
  endpoints: builder => ({
    getMeteringDeviceGroups: builder.query<MeteringDeviceGroups[], void>({
      query: () => ({
        url: '/',
      }),
    }),
    getMeteringDeviceGroupsById: builder.query<MeteringDeviceGroups, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const {
  useGetMeteringDeviceGroupsQuery,
  useGetMeteringDeviceGroupsByIdQuery,
} = meteringDeviceGroupsApi;
