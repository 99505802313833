import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const disconnectionReasonsApi = createApi({
  reducerPath: 'disconnectionReasonsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/disconnection-reasons`,
  }),
  endpoints: builder => {
    return {
      getDisconnectionReasons: builder.query<ReferenceBook[], void>({
        query: () => ({
          url: ``,
        }),
      }),
    };
  },
});

export const { useGetDisconnectionReasonsQuery } = disconnectionReasonsApi;
