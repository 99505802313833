import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import {
  MeteringPointAct,
  MeteringPointActDevice,
  MeteringPointActEquipment,
  MeteringPointActFilters,
  MeteringPointActModem,
  MeteringPointActPipelineSection,
  MeteringPointActQuery,
  MeteringPointActRequestBody,
  MeteringPointActSeal,
} from './types';

export const meteringPointActApi = createApi({
  reducerPath: 'meteringPointActApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL + '/metering-point-acts',
  }),
  tagTypes: [
    'MeteringPointAct',
    'MeteringPointActs',
    'MeteringPointActDevice',
    'MeteringPointActEquipment',
    'MeteringPointActModem',
    'MeteringPointActPipelineSection',
    'MeteringPointActSeal',
  ],
  endpoints: builder => ({
    getMeteringPointActs: builder.query<
      MeteringPointActQuery[],
      MeteringPointActFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['MeteringPointAct', 'MeteringPointActs'],
    }),
    getMeteringPointActById: builder.query<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringPointAct'],
    }),
    createMeteringPointAct: builder.mutation<
      MeteringPointAct,
      MeteringPointActRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id: result.id },
      ],
    }),
    updateMeteringPointAct: builder.mutation<
      MeteringPointAct,
      MeteringPointActRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id: result.id },
      ],
    }),
    deleteMeteringPointActById: builder.mutation<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id },
      ],
    }),
    getMeteringPointActDevices: builder.query<MeteringPointActDevice, string>({
      query: meteringPointActId => ({
        url: `/${meteringPointActId}/devices`,
      }),
      providesTags: ['MeteringPointActDevice'],
    }),
    getMeteringPointActEquipment: builder.query<
      MeteringPointActEquipment,
      string
    >({
      query: meteringPointActId => ({
        url: `/${meteringPointActId}/equipment`,
      }),
      providesTags: ['MeteringPointActEquipment'],
    }),
    getMeteringPointActModems: builder.query<MeteringPointActModem, string>({
      query: meteringPointActId => ({
        url: `/${meteringPointActId}/modems`,
      }),
      providesTags: ['MeteringPointActModem'],
    }),
    getMeteringPointActPipelineSections: builder.query<
      MeteringPointActPipelineSection,
      string
    >({
      query: meteringPointActId => ({
        url: `/${meteringPointActId}/pipeline-sections`,
      }),
      providesTags: ['MeteringPointActPipelineSection'],
    }),
    getMeteringPointActSeals: builder.query<MeteringPointActSeal, string>({
      query: meteringPointActId => ({
        url: `/${meteringPointActId}/seals`,
      }),
      providesTags: ['MeteringPointActSeal'],
    }),
  }),
});

export const {
  useGetMeteringPointActsQuery,
  useGetMeteringPointActByIdQuery,
  useCreateMeteringPointActMutation,
  useUpdateMeteringPointActMutation,
  useDeleteMeteringPointActByIdMutation,
  useGetMeteringPointActDevicesQuery,
  useGetMeteringPointActModemsQuery,
  useGetMeteringPointActEquipmentQuery,
  useGetMeteringPointActPipelineSectionsQuery,
  useGetMeteringPointActSealsQuery,
} = meteringPointActApi;
