import { Employee } from '@/features/employees/types';
import { Settlement, Street } from '@/features/locations/types';

export enum OrganizationTypeId {
  MainOffice = 'b419c55c-e197-4338-893f-4daa4050baf5',
  GasDistributionNetwork = 'f2dc1a95-3dbe-4517-8e9f-487e702607c2',
  StructuralSubdivision = '86b7f0a6-4160-4829-bca0-e8465d953439',
}

export interface OrganizationDetails {
  id?: string;
  phoneNumber: string;
  logo: string;
  websiteUrl: string;
  sampleContractText: string;
}

export interface Certification {
  id?: string;
  code: string;
  date: string;
}

export interface Address {
  id?: string;
  settlement: Settlement | null;
  street: Street | null;
  buildingNumber: string;
  corps?: string;
  officeNumber: string;
  floorNumber?: number;
  description?: string;
}

export interface Organization {
  id: string;
  classifier?: any;
  economicSector?: any;
  type: ReferenceBook;
  parentId?: string;
  name: string;
  fullName: string;
  taxCode: string;
  eicSymbol: string;
  prefix: string;
  eic: string;
  eicGasDistributionStation: string;
  startDate: string;
  address: Address;
  certification: Certification;
  details: OrganizationDetails;
  representative: {
    id?: string;
    firstName: string;
    middleName: string;
    lastName: string;
    departmentId?: string;
    positionId: string;
    email?: string;
    startDate?: string;
    endDate?: string;
    phoneNumber?: string;
  };
}

export type OrganizationInput = Omit<
  Organization,
  'type' | 'classifier' | 'economicSector' | 'representative' | 'address'
> & {
  typeId: string;
  classifierId: string;
  economicSectorId: string;
  representative: Omit<Employee, 'department' | 'position' | 'status'> & {
    departmentId: string;
    positionId: string;
  };
  address: Omit<Address, 'settlement' | 'street'> & {
    settlementId: string;
    streetId?: number;
    streetName?: string;
    streetType?: string;
  };
};

export type OrganizationBase = Pick<
  Organization,
  'id' | 'name' | 'parentId' | 'fullName' | 'eic' | 'taxCode' | 'type'
>;

export interface ReferenceBook<T = string> {
  id: T;
  type: string;
  value: string;
  order?: number;
  localizationKey: string;
}

export type OrganizationFilters = Partial<{
  name: string;
  typeId: string;
  includeMainOrganization: boolean;
}> | void;
