import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeterLocations } from './types';

export const meterLocationsApi = createApi({
  reducerPath: 'meterLocationsApi',
  tagTypes: ['MeterLocations'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/meter-locations`,
  }),
  endpoints: builder => ({
    getMeterLocations: builder.query<MeterLocations[], void>({
      query: () => ({
        url: '/',
      }),
    }),
    getMeterLocationById: builder.query<MeterLocations, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetMeterLocationsQuery, useGetMeterLocationByIdQuery } =
  meterLocationsApi;
