import AxiosMockAdapter from 'axios-mock-adapter';

import instance from '@/utils/http';

import { mockActs } from './mock';

const mock = new AxiosMockAdapter(instance, { delayResponse: 1000 });
mock.onPost('metering-point-acts/query').reply(200, mockActs);
mock.onGet(/metering-point-acts\/([^/]+)$/).reply(200, mockActs[0]);
mock.onAny().passThrough();
