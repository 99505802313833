import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

const ContractDetailsCommercialMeteringPoints = React.lazy(
  () => import('@/pages/ContractDetails/views/CommercialMeteringPoints'),
);

const ContractDetailsInfo = React.lazy(
  () => import('@/pages/ContractDetails/views/Information'),
);

export const contractDetailsRoutes: RouteObject[] = [
  {
    element: <ContractDetailsInfo />,
    index: true,
  },
  {
    path: Pages.COMMERCIAL_METERING_POINTS_PART,
    element: <ContractDetailsCommercialMeteringPoints />,
  },
];
