import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNode, MeteringNodeFilter } from './types';

export const meteringNodeApi = createApi({
  reducerPath: 'meteringNodeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/gas-metering-nodes`,
  }),
  tagTypes: ['MeteringNode', 'MeteringNodes'],
  endpoints: builder => ({
    getMeteringNodes: builder.query<MeteringNode[], MeteringNodeFilter>({
      query: ({
        meteringPointId = null,
        commercialMeteringPointId = null,
        meteringDeviceGroupId = null,
      }) => ({
        url: '/query',
        method: 'POST',
        data: {
          meteringPointId,
          commercialMeteringPointId,
          meteringDeviceGroupId,
        },
      }),
      providesTags: ['MeteringNode', 'MeteringNodes'],
    }),
    getMeteringNodeById: builder.query<MeteringNode, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringNode', 'MeteringNodes'],
    }),
    createMeteringNode: builder.mutation<MeteringNode, MeteringNode>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringNode',
        { type: 'MeteringNode', id: result.id },
      ],
    }),
    updateMeteringNodeById: builder.mutation<MeteringNode, MeteringNode>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringNode',
        { type: 'MeteringNode', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringNodesQuery,
  useCreateMeteringNodeMutation,
  useGetMeteringNodeByIdQuery,
  useUpdateMeteringNodeByIdMutation,
} = meteringNodeApi;
