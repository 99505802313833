import { ReactNode, useEffect, useState } from 'react';

import { InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts';
import {
  AuthProvider,
  hasAuthParams,
  useAuth as useOIDCAuth,
} from 'react-oidc-context';

import useAuth from '@/hooks/useAuth';
import tokenManager from '@/utils/tokenManager';

const AuthCheck = () => {
  const auth = useOIDCAuth();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (auth.events) {
      auth.events.addUserSignedIn(() => {
        console.log('Silent token renewal successful');
        tokenManager.setState(auth.user?.access_token);
      });

      auth.events.addSilentRenewError(() => {
        auth.signinRedirect({ state: window.location.pathname });
      });
    }

    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect({ state: window.location.pathname });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <div>Authorizing...</div>;
  }

  if (auth.error) {
    auth.signinRedirect({ state: window.location.pathname });
    return;
  }

  if (auth.isAuthenticated) {
    tokenManager.setState(auth.user?.access_token);
    return;
  }

  return;
};

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { oidc: auth } = useAuth();

  if (auth.isAuthenticated) {
    return children;
  }

  return null;
};

const Provider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider
      authority={import.meta.env.VITE_AUTHORITY}
      client_id={import.meta.env.VITE_CLIENT_ID}
      redirect_uri={import.meta.env.VITE_REDIRECT_URI}
      post_logout_redirect_uri={import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI}
      scope={import.meta.env.VITE_SCOPE}
      stateStore={new WebStorageStateStore({ store: window.localStorage })}
      userStore={new WebStorageStateStore({ store: new InMemoryWebStorage() })}
      automaticSilentRenew
      loadUserInfo
    >
      <AuthCheck />
      <PrivateRoute>{children}</PrivateRoute>
    </AuthProvider>
  );
};

export default Provider;
