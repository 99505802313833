import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointActType } from './types';

export const meteringPointActTypesApi = createApi({
  reducerPath: 'meteringPointActTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-point-act-types`,
  }),
  endpoints: builder => ({
    getMeteringPointActTypes: builder.query<MeteringPointActType[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const { useGetMeteringPointActTypesQuery } = meteringPointActTypesApi;
