import { Suspense } from 'react';

import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars';
import 'overlayscrollbars/overlayscrollbars.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AuthProvider from '@/providers/AuthProvider';
import MaterialProvider from '@/providers/MaterialProvider';
import { OrganizationProvider } from '@/providers/OrganizationProvider';

import { LicenseInfo } from '@mui/x-license';

import { NotificationProvider } from '@components/Notification/NotificationProvider';

import store from './app/store';
import { getRoutes } from './route';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE);

OverlayScrollbars.plugin(ClickScrollPlugin);

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <MaterialProvider>
      <AuthProvider>
        <OrganizationProvider>
          <Suspense>
            <NotificationProvider>
              <RouterProvider router={getRoutes()} />
            </NotificationProvider>
          </Suspense>
        </OrganizationProvider>
      </AuthProvider>
    </MaterialProvider>
  </Provider>,
);
