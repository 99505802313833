import { useAuth as useOIDCAuth } from 'react-oidc-context';

import { useGetUserByIdQuery } from '@/features/auth/authApi';

const useAuth = () => {
  const oidc = useOIDCAuth();
  const { user: session, signoutRedirect } = oidc;

  const userId = session?.profile?.sub;

  const { data, error } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });

  if (error) {
    signoutRedirect();
  }

  return {
    oidc,
    session,
    user: {
      ...data,
      fullName: [data?.lastName, data?.firstName, data?.middleName]
        .filter(Boolean)
        .join(' '),
    },
    logout: () => {
      signoutRedirect();
    },
  };
};

export default useAuth;
