import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

const MeteringPointInformation = React.lazy(
  () => import('@/pages/MeteringPointDetails/views/Information'),
);
const MeteringPointNodes = React.lazy(() => import('@/pages/Nodes'));
const MeteringNodeInformation = React.lazy(
  () => import('@/pages/NodeDetails/views/Information'),
);
const MeteringNodeSeal = React.lazy(() => import('@/pages/Seal'));
const SealDetails = React.lazy(() => import('@/forms/MeteringNodeSealForm'));

const MeteringDevices = React.lazy(() => import('@/pages/Devices'));

const CommercialMeteringPointEquipment = React.lazy(
  () =>
    import(
      '@/pages/CommercialMeteringPointDetails/views/GasConsumingEquipment'
    ),
);

const GasConsumingEquipmentFormView = React.lazy(
  () => import('@/pages/CommercialMeteringPointDetails/views/Form'),
);

const MeteringModemDetails = React.lazy(() => import('@/forms/MeteringModem'));
const MeteringDeviceDetails = React.lazy(
  () => import('@/forms/MeteringDeviceForm/MeteringDeviceFormPreviewMode'),
);

const MeteringPointActs = React.lazy(() => import('@/pages/MeteringPointActs'));

// TODO: move to Pages in case if this needed
export const MeteringPointsChildrenPath = {
  METERING_POINT_ACTS: 'metering-point-acts',
  METERING_NODE: 'metering-nodes',
  METERING_NODE_SEAL: 'seals',
  DEVICES: 'devices',
  MODEMS: 'modems',
};

export const meteringPointsRoutes: RouteObject[] = [
  {
    element: <MeteringPointInformation />,
    index: true,
  },
  {
    path: MeteringPointsChildrenPath.METERING_POINT_ACTS,
    element: <MeteringPointActs />,
  },
  {
    // Вузел обліку газу  ВОГ / GasMeteringNode
    path: MeteringPointsChildrenPath.METERING_NODE,
    element: <MeteringPointNodes />,
  },
  {
    path: MeteringPointsChildrenPath.METERING_NODE_SEAL,
    element: <MeteringNodeSeal />,
    children: [{ path: ':sealId', element: <SealDetails /> }],
  },
  {
    path: Pages.DEVICES,
    element: <MeteringDevices />,
    children: [
      {
        path: MeteringPointsChildrenPath.MODEMS + '/:modemId',
        element: <MeteringModemDetails />,
      },
      {
        path: ':deviceId',
        element: <MeteringDeviceDetails />,
      },
    ],
  },
  {
    path: `${Pages.EQUIPMENT}`,
    element: <CommercialMeteringPointEquipment />,
    children: [
      {
        path: ':gasConsumingEquipmentId',
        element: <GasConsumingEquipmentFormView />,
      },
    ],
  },
];

export const meteringPointsNodeRoutes: RouteObject[] = [
  {
    index: true,
    element: <MeteringNodeInformation />,
  },
  {
    path: Pages.DEVICES,
    element: <MeteringDevices />,
    children: [
      {
        path: MeteringPointsChildrenPath.MODEMS + '/:modemId',
        element: <MeteringModemDetails />,
      },
      {
        path: ':deviceId',
        element: <MeteringDeviceDetails />,
      },
    ],
  },
  {
    path: `${Pages.EQUIPMENT}`,
    element: <CommercialMeteringPointEquipment />,
    children: [
      {
        path: ':gasConsumingEquipmentId',
        element: <GasConsumingEquipmentFormView />,
      },
    ],
  },
];
