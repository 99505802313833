import { createApi } from '@reduxjs/toolkit/query/react';

import { MeteringPointStatusHistory } from '@/features/meteringPoint/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  CommercialMeteringPoint,
  CommercialMeteringPointFilters,
  CommercialMeteringPointRequestPayload,
} from './types';

export const commercialMeteringPointsApi = createApi({
  reducerPath: 'commercialMeteringPointsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/commercial-metering-points`,
  }),
  tagTypes: ['CommercialMeteringPoints', 'CommercialMeteringPoint'],
  endpoints: builder => {
    return {
      getCommercialMeteringPoints: builder.query<
        CommercialMeteringPoint[],
        CommercialMeteringPointFilters | void
      >({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters || {},
        }),
        providesTags: ['CommercialMeteringPoints'],
      }),
      getCommercialMeteringPointById: builder.query<
        CommercialMeteringPoint,
        string
      >({
        query: id => ({
          url: `/${id}`,
        }),
        providesTags: (_, __, id) => [{ type: 'CommercialMeteringPoint', id }],
      }),
      createCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        CommercialMeteringPointRequestPayload
      >({
        query: data => ({
          url: ``,
          method: 'POST',
          data,
        }),
        invalidatesTags: result => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id: result.id },
        ],
      }),
      updateCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        CommercialMeteringPointRequestPayload
      >({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: result => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id: result.id },
        ],
      }),
      deleteCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        string
      >({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id },
        ],
      }),
      getCommercialMeteringPointByIdStatusHistory: builder.query<
        MeteringPointStatusHistory[],
        string
      >({
        query: id => ({
          url: `/${id}/status-history`,
        }),
      }),
    };
  },
});

export const {
  useGetCommercialMeteringPointsQuery,
  useGetCommercialMeteringPointByIdQuery,
  useCreateCommercialMeteringPointMutation,
  useUpdateCommercialMeteringPointMutation,
  useDeleteCommercialMeteringPointMutation,
  useGetCommercialMeteringPointByIdStatusHistoryQuery,
} = commercialMeteringPointsApi;
