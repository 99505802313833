import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from './authApi';
import { User } from './types';

interface AuthState {
  user?: User;
}

const initialState: AuthState = {
  user: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    _: () => {},
  },
  extraReducers: builder =>
    builder.addMatcher(
      authApi.endpoints?.getUserById.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        state.user = action.payload;
      },
    ),
  selectors: {
    selectCurrentUser: state => state.user,
  },
});

export const { selectCurrentUser } = authSlice.selectors;

export default authSlice;
