import { Theme } from '@mui/joy';

export const getGlobalScrollStyles = (theme: Theme) => ({
  '*::-webkit-scrollbar': {
    width: theme.spacing(1),
  },
  '*::-webkit-scrollbar-track': {
    background: theme.palette.background.level1,
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral[500],
    borderRadius: theme.radius.sm,
    border: 'none',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.neutral[700],
  },
  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.neutral[500]} ${theme.palette.background.level1}`,
  },
});
