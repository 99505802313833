import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const counterpartRequestsActionApi = createApi({
  reducerPath: 'counterpartRequestsActionApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/code-request-actions`,
  }),
  endpoints: builder => ({
    counterpartRequestsAction: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: `/`,
      }),
    }),
  }),
});

export const { useCounterpartRequestsActionQuery } =
  counterpartRequestsActionApi;
